const dlxhostToprojectIdsMap = {
  "dlx-lskce5vhfa-ew.a.run.app": "plainfield-prod-ohk2",
  "dlx.plainfield.se": "plainfield-prod-ohk2",
  "dlx-ilmrladkiq-ew.a.run.app": "plainfield-venus-ljlx",
  "dlx-juw6zpbl5a-ew.a.run.app": "plainfield-lab-gsr1",
  "dlx-le73x3wtnq-ew.a.run.app": "plainfield-pluto-w17x",
  "dlx-3q56ycefoa-ew.a.run.app": "plainfield-tellus-cpp6",
  "dlx-7dhlrjawuq-ew.a.run.app": "plainfield-titan-fpk2",
  "localhost:5000": "local-project"
};

const currentHost = window.location.host;
// the projectId are used in the GUI to render correct link to logs on GCP
const projectId = dlxhostToprojectIdsMap[currentHost] || "missing-project-id";
export default projectId;
