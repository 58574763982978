import "./App.css";
import useSWR, {useSWRConfig} from "swr";
import DataTable from "./components/DataTable";
import {useNavigate, BrowserRouter, Routes, Route} from "react-router-dom";
import url from "./utils/url";
import Letter from "./letter";

import {ThemeProvider, createTheme} from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark"
  }
});

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function App() {
  const {data} = useSWR(`${url}/dead-letters`, fetcher, {
    revalidateOnReconnect: true
  });
  const {mutate} = useSWRConfig();

  async function handleMessage(rows, resend) {
    const ids = rows.map((r) => r.messageId);
    mutate(
      `${url}/dead-letters`,
      {
        ...data,
        data: data.rows.filter((row) => !ids.includes(row.messageId))
      },
      false
    );
    await fetch(`${url}/handle-message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({rows, resend})
    });
    mutate(`${url}/dead-letters`);
  }
  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="letter/:id" element={<Letter />} />
            <Route path="/" element={<Home handleMessage={handleMessage} data={data} />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

function Home({handleMessage, data}) {
  const navigate = useNavigate();
  return (
    <div style={{height: "90%"}}>
      <DataTable
        onRowSelected={(messageId) => navigate(`/letter/${messageId}`)}
        onResend={(rows) => handleMessage(rows, true)}
        onDiscard={(rows) => handleMessage(rows)}
        rows={data?.rows ?? []}
      />
    </div>
  );
}
