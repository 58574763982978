import React, {useState, useEffect} from "react";
import useSWR from "swr";
import {useNavigate, useParams} from "react-router-dom";
import {
  TextField,
  Button,
  Paper,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle
} from "@mui/material";
import url from "./utils/url";

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function Letter() {
  const {id} = useParams();
  const navigate = useNavigate();

  const {
    data: letterContent,
    attributes: messageAttributes,
    type,
    error
  } = useSWR(id ? `${url}/dead-letter/${id}` : null, fetcher);

  const {data, attributes, publishTime} = letterContent || {};
  const [editData, setEditData] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editAttributes, setEditAttributes] = useState(null);
  const [attributesError, setAttributesError] = useState(null);
  const [dataError, setDataError] = useState(null);

  useEffect(() => {
    if (editData == null) {
      setEditData(JSON.stringify(data?.data, null, 2));
    }
    if (editId == null) {
      setEditId(data?.id);
    }
    if (editAttributes == null && data?.attributes != null) {
      setEditAttributes(JSON.stringify(data?.attributes ?? {}, null, 2));
    }
  }, [data?.data]);

  async function handleMessage(row, resend) {
    navigate("/");
    await fetch(`${url}/handle-message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({rows: [row], resend})
    });
  }
  if (error || data?.error != null) {
    return (
      <div>
        <Dialog open aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle color="error" id="alert-dialog-title">
            ERROR
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{width: 400}} id="alert-dialog-description">
              {data?.error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => navigate("/")}>Back Home</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%"
      }}
    >
      <Paper
        style={{marginLeft: 30, marginTop: 150, padding: 20, width: 800, display: "flex", flexDirection: "column"}}
      >
        <Row header="Key">{messageAttributes?.key ?? ""}</Row>
        <Row header="Correlation Id">{data?.correlationId ?? ""}</Row>
        <Row header="Type">{data?.type ?? ""}</Row>
        <Row header="Time">{new Date(publishTime).toLocaleString() ?? ""}</Row>
        <Row header="Id" editable onChange={(val) => setEditId(val)}>
          {editId ?? ""}
        </Row>
        <DataRow
          error={attributesError}
          header="Attributes"
          multiline
          editable
          onChange={(val) => {
            setEditAttributes(val);
            try {
              JSON.parse(val);
              setAttributesError(null);
            } catch (e) {
              setAttributesError(`Can not parse JSON correctly \n ${e}`);
            }
          }}
        >
          {editAttributes ?? ""}
        </DataRow>
        <Button
          style={{alignSelf: "flex-start", marginBottom: 20}}
          disabled={attributesError != null}
          onClick={() => setEditAttributes(JSON.stringify(JSON.parse(editAttributes), null, 2))}
        >
          Format Data
        </Button>
        <DataRow
          error={dataError}
          header="Data"
          multiline
          editable
          onChange={(val) => {
            setEditData(val);
            try {
              JSON.parse(val);
              setDataError(null);
            } catch (e) {
              setDataError(`Can not parse JSON correctly \n ${e}`);
            }
          }}
        >
          {editData ?? ""}
        </DataRow>
        <Button
          style={{alignSelf: "flex-start", marginBottom: 20}}
          disabled={dataError != null}
          onClick={() => setEditData(JSON.stringify(JSON.parse(editData), null, 2))}
        >
          Format Data
        </Button>
        <div style={{marginTop: 60}}>
          <Button onClick={() => handleMessage(letterContent)} variant="contained" color="error">
            Discard
          </Button>
          <Button
            disabled={dataError != null || attributesError != null || !editId}
            style={{marginLeft: 10}}
            variant="contained"
            color="info"
            onClick={() => {
              handleMessage(
                {
                  ...letterContent,
                  data: {
                    data: JSON.parse(editData) ?? [],
                    correlationId: data.correlationId,
                    attributes: JSON.parse(editAttributes) ?? data.attributes,
                    id: editId,
                    type: data.type
                  }
                },
                true
              );
            }}
          >
            Resend
          </Button>
        </div>
      </Paper>
    </div>
  );
}

function Row({header, children, editable, onChange, error}) {
  return (
    <div style={{display: "flex", alignItems: "baseline", marginBottom: 20}}>
      <TextField
        error={error != null}
        helperText={error}
        style={{flex: 1}}
        disabled={!editable}
        label={header}
        value={children}
        onChange={(event) => onChange(event.target.value)}
        variant="standard"
      />
    </div>
  );
}

function DataRow({header, children, editable, multiline, onChange, error}) {
  return (
    <div style={{display: "flex", alignItems: "baseline", marginBottom: 20}}>
      <TextField
        error={error != null}
        helperText={error}
        style={{flex: 1}}
        disabled={!editable}
        label={header}
        value={children}
        onChange={(event) => onChange(event.target.value)}
        variant="standard"
        multiline={multiline}
        rows={
          multiline && children != null && children.split(/\r\n|\r|\n/).length > 5
            ? children.split(/\r\n|\r|\n/).length
            : 5
        }
      />
    </div>
  );
}
